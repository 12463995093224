
import { Options, Prop } from "vue-property-decorator";
import ImageQuestion from "@/components/Pages/TheoryPreExams/Questions/ImageQuestion.vue";
import BaseQuestion from "@/components/Pages/TheoryPreExams/Questions/BaseQuestion.vue";
import { mixins } from "vue-class-component";
import QuestionMixin from "@/mixins/TheoryQuestions/QuestionMixin";
import { KNOWLEDGE_AREA_MAPPER } from "@/maps/KnowledgeAreaMapper";
import VideoAnswer from "@/components/Pages/TheoryPreExams/Questions/VideoAnswer.vue";

@Options({
  components: { VideoAnswer, BaseQuestion, ImageQuestion },
})
export default class TheoryPreExamsAnswer extends mixins(QuestionMixin) {
  public name = "TheoryPreExamsAnswer";

  @Prop()
  public question!: any;

  private get knowledgeAreaMap() {
    return KNOWLEDGE_AREA_MAPPER;
  }

  private close(): void {
    this.$emit("close");
  }
}
