
import { Options, Prop, Watch } from "vue-property-decorator";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { mixins } from "vue-class-component";
import UserMixin from "@/mixins/UserMixin";
import StudentPreExamEducationMixin from "@/mixins/StudentEducations/StudentPreExamEducationMixin";
import { namespace } from "s-vuex-class";
import TheoryPreExamsAnswer from "@/components/Pages/TheoryPreExams/TheoryPreExamsAnswer.vue";

const StudentEducationModule = namespace("student-education");
const TheoryPreExamModule = namespace("theory-pre-exams");
@Options({
  components: {
    TheoryPreExamsAnswer,
    DefaultLayout,
  },
})
export default class TheoryPreExamsResult extends mixins(StudentPreExamEducationMixin, UserMixin) {
  public name = "TheoryPreExamsResult";

  @TheoryPreExamModule.Action("resetExam")
  public resetExamAction: any;

  @TheoryPreExamModule.Getter("getQuestions")
  public questions: any;

  @TheoryPreExamModule.Getter("getBasicQuestions")
  private basicQuestions: any;

  @TheoryPreExamModule.Getter("getSpecificQuestions")
  private specificQuestions: any;

  @TheoryPreExamModule.Getter("getAnswerSuccess")
  public theoryPreExamsAnswerSuccess: any;

  @StudentEducationModule.Getter("getSelectedTheoryStudentEducation")
  public selectedTheoryStudentEducation: any;

  @Prop({ default: 0 })
  public preExamProgress!: any;

  private basicPointsCount = 0;
  private basicIncorrectPointsCount = 0;

  private specificPointsCount = 0;
  private specificIncorrectPointsCount = 0;

  private basicAnswersByParagraph: any = {};
  private specificAnswersByParagraph: any = {};
  private selectedQuestion: any = {};
  private showAnswer = false;

  private answersByParagraphColumnWidthStyle(answersByParagraph: any): any {
    let longestParagraph = 0;
    for (const paragraph in answersByParagraph) {
      const paragraphLength = answersByParagraph[paragraph].answersStatus.length;
      if (paragraphLength > longestParagraph) {
        longestParagraph = paragraphLength;
      }
    }
    return { width: 100 / longestParagraph + "%" };
  }

  get incorrectPointsCount(): number {
    return this.basicIncorrectPointsCount + this.specificIncorrectPointsCount;
  }

  private selectQuestion(id: number, type: number): void {
    this.selectedQuestion = type === 0 ? this.basicQuestions.find((q: any) => q.id == id) : this.specificQuestions.find((q: any) => q.id == id);
    this.showAnswer = true;
  }

  private onAnswerClose(): void {
    this.showAnswer = false;
  }

  @Watch("basicQuestions", { immediate: true, deep: true })
  private onBasicQuestionsChange(questions: any): void {
    if (questions && questions.length) {
      this.basicIncorrectPointsCount = 0;
      this.basicPointsCount = 0;
      this.basicAnswersByParagraph = {};
      // all in one loop not very readable, but faster :(
      for (const question of questions) {
        this.basicPointsCount += question.points;
        if (!question.correct) {
          this.basicIncorrectPointsCount += question.points;
        }
        // paragraphs
        if (!this.basicAnswersByParagraph[question.paragraph.id]) {
          this.basicAnswersByParagraph[question.paragraph.id] = {
            name: question.paragraph.name,
            countCorrect: question.correct ? 1 : 0,
            answersStatus: [
              {
                id: question.id,
                correct: question.correct,
              },
            ],
          };
        } else {
          if (question.correct) {
            this.basicAnswersByParagraph[question.paragraph.id].countCorrect++;
          }
          this.basicAnswersByParagraph[question.paragraph.id].answersStatus.push({
            id: question.id,
            correct: question.correct,
          });
        }
      }
    }
  }

  @Watch("specificQuestions", { immediate: true, deep: true })
  private onSpecificQuestionsChange(questions: any): void {
    if (questions && questions.length) {
      this.specificIncorrectPointsCount = 0;
      this.specificPointsCount = 0;
      this.specificAnswersByParagraph = {};
      // all in one loop not very readable, but faster :(
      for (const question of questions) {
        this.specificPointsCount += question.points;
        if (!question.correct) {
          this.specificIncorrectPointsCount += question.points;
        }
        // paragraphs
        if (!this.specificAnswersByParagraph[question.paragraph.id]) {
          this.specificAnswersByParagraph[question.paragraph.id] = {
            name: question.paragraph.name,
            countCorrect: question.correct ? 1 : 0,
            answersStatus: [
              {
                id: question.id,
                correct: question.correct,
              },
            ],
          };
        } else {
          if (question.correct) {
            this.specificAnswersByParagraph[question.paragraph.id].countCorrect++;
          }
          this.specificAnswersByParagraph[question.paragraph.id].answersStatus.push({
            id: question.id,
            correct: question.correct,
          });
        }
      }
    }
  }
  private restartExam(): void {
    this.resetExamAction();
    this.$nextTick(() => location.reload());
  }

  public created(): void {
    window.addEventListener("beforeunload", this.onBeforeUnload);
  }
  private onBeforeUnload(e: BeforeUnloadEvent): any {
    // reset if the user hits the F5
    this.resetExamAction();
    return undefined;
  }

  public unmounted() {
    window.removeEventListener("beforeunload", this.onBeforeUnload);
  }
}
