
import { Options, Prop, Watch } from "vue-property-decorator";
import MultiChoice from "@/components/Pages/TheoryPreExams/Parts/MultiChoice.vue";
import FreeText from "@/components/Pages/TheoryPreExams/Parts/FreeText.vue";
import QuestionMixin from "@/mixins/TheoryQuestions/QuestionMixin";
import MediaQuestionMixin from "@/mixins/TheoryQuestions/MediaQuestionMixin";
import { mixins } from "vue-class-component";
import ModalMixin from "@/mixins/ModalMixin.ts";
import ImageLoadingMixin from "@/mixins/Media/ImageLoadingMixin";
import VideoLoadingMixin from "@/mixins/Media/VideoLoadingMixin";
import VideoPlayer from "@/components/VideoPlayer.vue";
import FscImage from "@/components/FscImage.vue";
import { useBackButton } from "@ionic/vue";
import FscModal from "@/components/FscModal.vue";

@Options({
  components: { FscImage, VideoPlayer, FreeText, MultiChoice },
})
export default class VideoQuestion extends mixins(QuestionMixin, MediaQuestionMixin, ModalMixin, ImageLoadingMixin, VideoLoadingMixin) {
  private authThumbUrl = "";
  private prevQuestion: any = {};

  @Prop({ default: false })
  public disabled!: any;

  public startQuestionWarningModal = false;
  public videoModal = false;

  private showQuestions(): void {
    this.hideStartQuestionWarningModal(null);
    this.$emit("onVideoQuestionChange", {
      questionsVisible: true,
    });
  }

  private showStartQuestionWarningModal(e: PointerEvent) {
    this.showModal(e, "startQuestionWarningModal");
  }

  private hideStartQuestionWarningModal(e: any | null) {
    this.hideModal(e, "startQuestionWarningModal");
  }

  private showVideo(e: PointerEvent) {
    if (this.question.countSeen <= 0) {
      return;
    }
    this.showModal(e, "videoModal");
    this.$emit("onVideoQuestionChange", {
      countSeen: this.question.countSeen - 1,
    });
  }

  private hideVideo(e: any) {
    this.hideModal(e, "videoModal");
    this.authThumbUrl = this.authEndThumbUrl;
  }

  @Watch("question", { immediate: true, deep: true })
  private onQuestionChange(question: any): void {
    if (question) {
      if (!this.prevQuestion || this.prevQuestion.id != question.id) {
        this.authThumbUrl = this.authStartThumbUrl;
      }
      this.prevQuestion = question;
    }
  }

  public created() {
    useBackButton(1, (processNextHandler) => {
      const startQuestionWarningModalRef = this.$refs["startQuestionWarningModal"] as FscModal;
      const videoModalRef = this.$refs["videoModal"] as FscModal;

      if (startQuestionWarningModalRef?.showModal || videoModalRef?.showModal) {
        this.onVideoEnded();
      } else {
        processNextHandler();
      }
    });
  }

  private onVideoEnded(): void {
    this.hideStartQuestionWarningModal(null);
    this.hideVideo(null);
  }

  private showImage(e: PointerEvent) {
    this.showModal(e, "imageModal");
  }

  private hideImage(e: PointerEvent) {
    this.hideModal(e, "imageModal");
  }
}
