
import { Options, Prop, Watch } from "vue-property-decorator";
import MultiChoice from "@/components/Pages/TheoryPreExams/Parts/MultiChoice.vue";
import FreeText from "@/components/Pages/TheoryPreExams/Parts/FreeText.vue";
import QuestionMixin from "@/mixins/TheoryQuestions/QuestionMixin";
import MediaQuestionMixin from "@/mixins/TheoryQuestions/MediaQuestionMixin";
import ModalMixin from "@/mixins/ModalMixin.ts";
import { mixins } from "vue-class-component";
import ImageLoadingMixin from "@/mixins/Media/ImageLoadingMixin";
import FscImage from "@/components/FscImage.vue";

@Options({
  components: { FscImage, FreeText, MultiChoice },
})
export default class ImageQuestion extends mixins(QuestionMixin, MediaQuestionMixin, ModalMixin, ImageLoadingMixin) {
  @Prop({ default: false })
  public disabled!: any;
  private prevQuestion: any = {};

  public imageModal = false;

  private showImage(e: PointerEvent) {
    this.showModal(e, "imageModal");
  }

  private hideImage(e: PointerEvent) {
    this.hideModal(e, "imageModal");
  }

  @Watch("question", { immediate: true, deep: true })
  private onQuestionChange(question: any): void {
    if (question) {
      if (!this.prevQuestion || this.prevQuestion.id != question.id) {
        this.imgLoading = false;
        this.imgLoadError = false;
      }
      this.prevQuestion = question;
    }
  }
}
